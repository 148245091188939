.about-container {
	position: relative;
	display: grid;

	height: 100vh;
	width: 100vw;
}

.about-container div {
	position: relative;
	display: grid;
	margin: 2rem;

	justify-content: center;
	align-content: center;
}

.about-container div h1, .about-container div p {
	text-align: center;
}
.about-container div p {
}

.about-container .superwoman {
	position: absolute;

	left: 50%;
	top: 50%;

	height: 80%;
	width: auto;

	transform: translate(-50%, -50%);
}

@media only screen and (max-width: 576px) {
	.about-container {
		grid-template-rows: 50% 50%;
	}
}
@media only screen and (min-width: 576px) {
	.about-container {
		grid-template-columns: 50% 50%;
	}
}

