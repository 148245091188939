html {
	--color-primary: #F4E04D;
	--color-secondary: #8DB1AB;

	--color-primary-alt: #f9f06b;
	--color-secondary-alt: #587792;

	--color-tertiary: #CEE397;

	--color-background-primary: black;
	--color-background-secondary: #1a1a1a;

	--color-text-primary: white;
	--color-text-secondary: green;

	color: var(--color-text-primary);
	background-color: var(--color-background-primary);


	--color-red: #F24333;
	--color-orange: #FA8E00;
	--color-yellow: #F4E04D;
	--color-green: #00AF54;
	--color-blue: #007CBE;
	--color-purple: #8783D1;
	--color-pink: #FFA3AF;

	scroll-behavior: smooth;
}

*[draggable="false"] {
	user-select: none;
}