.shop {
	color: black;
	background-color: var(--color-primary);
}

.shop > .container-1 {
	height: 80vh;
	width: 90vw;

	margin: 1rem;

	display: grid;
	color: white;
}

.shop .heading {
	text-align: center;
}

.shop .subcontainer {
}

.shop .subcontainer-1 {
	background-color: var(--color-background-secondary);

	padding: 1rem 2.5rem;
}

.shop .subcontainer-1 .name {
	color: var(--color-primary);
	font-family: "Object Sans";

	margin-botton: 1rem;

}
.shop .subcontainer-1 .property-name {
	height: 3rem;
	font-weight: bolder;
	margin-right: 1rem;
}
.shop .subcontainer-1 .property-value {
	color: var(--color-purple);
}
.shop .subcontainer-1 .property-value, .shop .subcontainer-1 .property-name {
	line-height: 3rem;
	border-bottom: 2px solid #ffffff20;
}
.shop .subcontainer-1 .properties {
	border-radius: .5rem;

	position: relative;
	display: grid;

	grid-template-columns: 1fr 1fr;

	background-color: var(--color-background-primary);

	padding: 0 1rem 2rem;
}

.shop .properties .heading {
	text-align: start;
	color: var(--color-primary);
	width: 100%;
}

.shop .subcontainer-2 {
	position: relative;

	overflow: hidden;
}
.shop .subcontainer-2 .subcontainer-bg {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;

	--spiral-primary: var(--color-background-primary);
	--spiral-secondary: var(--color-background-secondary);
	background: conic-gradient( 
    	var(--spiral-primary) 20deg,
    	var(--spiral-secondary) 20deg 40deg,
    	var(--spiral-primary) 40deg 60deg,
    	var(--spiral-secondary) 60deg 80deg,
    	var(--spiral-primary) 80deg 100deg,
    	var(--spiral-secondary) 100deg 120deg,
    	var(--spiral-primary) 120deg 140deg,
    	var(--spiral-secondary) 140deg 160deg,
    	var(--spiral-primary) 160deg 180deg,
    	var(--spiral-secondary) 180deg 200deg,
    	var(--spiral-primary) 200deg 220deg,
    	var(--spiral-secondary) 220deg 240deg,
    	var(--spiral-primary) 240deg 260deg,
    	var(--spiral-secondary) 260deg 280deg,
    	var(--spiral-primary) 260deg 300deg,
    	var(--spiral-secondary) 300deg 320deg,
    	var(--spiral-primary) 320deg 340deg,
    	var(--spiral-secondary) 340deg 360deg
    );

    scale: 2;

    animation: rotate-mug 10s linear infinite;
}
@keyframes rotate-mug {
	0% {
		rotate: 0deg;
	}
	100% {
		rotate: 360deg;
	}
}

.shop .mug {
	position: absolute;
	top: 50%;
	left: 55%;

	transform: translate(-50%, -50%);

	width: 100%;
	height: auto;
}



.shop .order-btn {
	display: grid;
	position: relative;

	background-color: var(--color-primary);
	border-radius: 2rem;
	padding: 0 1rem;

	grid-template-columns: auto auto;
	gap: 1rem;

	height: 3rem;

	align-content: center;

	transition: .3s;

	border: none;
}

.shop .order-btn span {
	align-self: center;
}

.shop .order-btn:hover {
	background-color: var(--color-primary-alt);
	scale: 1.03;
}

.shop .order-btn:active {
	background-color: white;
	scale: 1.01;
}

.shop .btn-container {
	position: relative;
	display: grid;

	width: 100%;
	padding: 2rem 0;

	justify-content: center;
	align-content: center;
}





@media only screen and (max-width: 576px) {
	.shop > .container-1 {
		grid-template-rows: 50% 50%;
		width: 95vw;
	}
	.shop .properties {
		display: none !important;
	}
	.shop .subcontainer-1 {
		border-radius: 2rem 2rem 0 0;
	}
	.shop .subcontainer-2 {
		border-radius: 0 0 2rem 2rem;
	}
}
@media only screen and (min-width: 576px) {
	.shop > .container-1 {
		grid-template-columns: 50% 50%;
	}
	.shop .subcontainer-1 {
		border-radius: 2rem 0 0 2rem;
	}
	.shop .subcontainer-2 {
		border-radius: 0 2rem 2rem 0;
	}
}