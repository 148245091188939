.barcode {
}

.barcode h1 {
	text-align: center;
}

.stats-container {
	display: grid;

	height: 80vh;
	width: 90vw;

	padding: 1rem;
	gap: 1rem;
	margin: 1rem;

	border-radius: 2rem;

/*	background-color: var(--color-background-secondary);
*/
	z-index: 0;
}

.stat-property {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;

	border: 1px solid var(--color-primary);
	background-color: var(--color-background-primary);
	border-radius: 1rem;

	align-content: center;
	padding: 0 2rem;
}

.stat-property::after {
	content: '';
	position: absolute;
	top: 0; left:0;
	width: 100%; height: 100%;

	background-color: red;

	z-index: -1;

	transform: translate(.5rem, .5rem);

	border-radius: 1rem;
	--shade: var(--color-primary);
	background: repeating-linear-gradient(45deg, #ffffff00, #ffffff00 4px, var(--shade) 4px, var(--shade) 6px);
}

.stat-name, .stat-value {
	font-family: 'Silkscreen';
}


.stat-name {
	font-weight: bolder;
}

.stat-value {

}

.shaded {
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(45deg, #92c9b1, #92c9b1 20px, #b3e0d2 20px, #b3e0d2 40px);
}