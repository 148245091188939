.smile-container {
	position: relative;
	display: grid;

	height: 100vh;
	width: 100vw;
}

.smile-container div {
	position: relative;
	display: grid;
	margin: 2rem;

	justify-content: center;
	align-content: center;
}

.smile .smile-rotate {
	width: 20vw;
	height: 20vw;
	position: relative;
	color: var(--color-green);
	animation: rotate infinite cubic-bezier(.34,0,.66,.99) 2s;
}

.smile div h1, .smile div p {
	text-align: center;
}
.smile div p {
}

.smile .communicate {
	position: absolute;

	left: 50%;
	top: 50%;

	height: 60%;
	width: auto;

	transform: translate(-50%, -50%);
}

.smile .smile-rotate svg {
	position: absolute;
	left: 50%; top: 50%;
	transform: translate(-50%, -50%);
}

@keyframes rotate {
	0% {
		rotate: 0deg;
	}
	30% {
		rotate: 0deg;
	}
	45% {
		rotate: 380deg;
	}
	100% {
		rotate: 360deg;
	}
}

.smile h1 {
	align-text: center;

	justify-self: center;
}

@media only screen and (max-width: 576px) {
	.smile-container {
		grid-template-rows: 50% 50%;
	}
}
@media only screen and (min-width: 576px) {
	.smile-container {
		grid-template-columns: 50% 50%;
	}
}
