.navbar {
	position: fixed;
	top: 50%;
	left: 1rem;

	transform: translateY(-50%);

	height: fit-content;
	width: 3rem;

	display: grid;
	gap: .5rem;

	justify-content: center;
	align-content: center;

	background-color: var(--color-background-secondary);

	border-radius: 1rem;
}

.navbar-item {
	line-height: 2rem;

	width: 2rem;
	height: 2rem;
	padding: .5rem;

	color: white;

	border-radius: 1rem;

	transition: .2s;
}

.navbar-item:hover {
	background-color: #ffffff10;
	scale: 1.05;

	z-index: 1;
}

.navbar-item.item-1:hover, .navbar-item.item-1[status="selected"] {
	color: var(--color-red);
}
.navbar-item.item-2:hover, .navbar-item.item-2[status="selected"] {
	color: var(--color-orange);
}
.navbar-item.item-3:hover, .navbar-item.item-3[status="selected"] {
	color: var(--color-yellow);
}
.navbar-item.item-4:hover, .navbar-item.item-4[status="selected"] {
	color: var(--color-green);
}
.navbar-item.item-5:hover, .navbar-item.item-5[status="selected"] {
	color: var(--color-blue);
}
.navbar-item.item-6:hover, .navbar-item.item-6[status="selected"] {
	color: var(--color-purple);
}

.navbar-item-bg {
	position: absolute;

	top: 0;
	left: 0;

	z-index: -1;

	background-color: #ffffffd0;
}


.navbar-item.item-1[status="selected"] ~ .navbar-item-bg {
	top: 0rem;
}
.navbar-item.item-2[status="selected"] ~ .navbar-item-bg {
	top: 3.5rem;
}
.navbar-item.item-3[status="selected"] ~ .navbar-item-bg {
	top: 7rem;
}
.navbar-item.item-4[status="selected"] ~ .navbar-item-bg {
	top: 10.5rem;
}
.navbar-item.item-5[status="selected"] ~ .navbar-item-bg {
	top: 14rem;
}
.navbar-item.item-6[status="selected"] ~ .navbar-item-bg {
	top: 17.5rem;
}