.varpage {
	position: relative;
	display: grid;

	justify-content: center;
	align-content: center;

	height: 100vh;
	width: 100vw;
}

@media only screen and (max-width: 576px) {
	.varpage {
		height: 50vh;
	}	
}
@media only screen and (min-width: 576px) {
	.varpage {
		height: 100vh;
	}	
}

.page {
	position: relative;
	display: grid;

	justify-content: center;
	align-content: center;

	height: 100vh;
	width: 100vw;
}