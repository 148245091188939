.home > .heading {
	font-family: "Bebas Neue";
	font-size: calc(10vw + 1.5rem);
}

.home > .kangaroo {
	position: absolute;

	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);

	width: 100vw;
	height: auto;

	filter: brightness(0) saturate(100%) invert(87%) sepia(54%) saturate(584%) hue-rotate(345deg) brightness(101%) contrast(91%);

	z-index: -1;
}
